<template>
  <div>
    <a-card :title="$t('上架记录详情')">
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">{{ $t("返回") }}</a-button>

      <a-spin :spinning="orderLoading">
        <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
        <a-descriptions bordered>
          <a-descriptions-item :label="$t('上架单号')">
            {{ shelveRecordItem.shelve_order_item?.number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('入库通知单号')">
            {{ shelveRecordItem.receipt_order_item?.number }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('仓库')">
            {{ shelveRecordItem.warehouse_item?.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('货主')">
            {{ shelveRecordItem.client_item?.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建人')">
            {{ shelveRecordItem.creator_item?.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('创建时间')">
            {{ shelveRecordItem.create_time }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :columns="curColumns"
          :data-source="shelveRecordMaterialItems"
          :loading="materialLoading"
          :pagination="false"
        >
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { shelveRecordRetrieve, shelveRecordMaterials } from "@/api/stockIn";
import JsBarcode from "jsbarcode";
import NP from "number-precision";

export default {
  data() {
    return {
      NP,
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          customRender: (_value, item) => item.material_item.number,
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          customRender: (_value, item) => item.material_item.name,
        },
        {
          title: this.$t("批次编号"),
          dataIndex: "batch_number",
          customRender: (_value, item) => item.batch_item.number,
        },
        {
          title: this.$t("容器编号"),
          dataIndex: "pallet_number",
          customRender: (_value, item) => item.pallet_item?.number,
        },
        {
          title: this.$t("质检状态"),
          dataIndex: "status_display",
          key: "quality_inspection_status",
        },
        {
          title: this.$t("库位编号"),
          dataIndex: "location_number",
          customRender: (_value, item) => item.location_item.number,
        },
        {
          title: this.$t("上架数量"),
          dataIndex: "shelve_quantity",
        },
      ],
      orderLoading: false,
      materialLoading: false,
      shelveRecord: undefined,
      shelveRecordItem: {},
      shelveRecordMaterialItems: [],
    };
  },
  computed: {
    enableQualityInspection() {
      return this.$store.state.user.enableQualityInspection;
    },
    curColumns() {
      const items = [];
      for (const item of this.columns) {
        if (!this.enableQualityInspection && item.key === "quality_inspection_status") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    initData() {
      document.getElementById("jsbarcode").html = "";
      this.shelveRecord = this.$route.query.id;

      this.orderLoading = true;
      shelveRecordRetrieve({ id: this.shelveRecord })
        .then((data) => {
          console.log(data);
          this.shelveRecordItem = data;
          new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.orderLoading = false;
        });

      this.materialLoading = true;
      shelveRecordMaterials({ id: this.shelveRecord })
        .then((data) => {
          this.shelveRecordMaterialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style>
.jsbarcode {
  float: right;
}
</style>
